Vue.component("v-select", VueSelect.VueSelect);
const demoCats = [
  'HR Agency', 'Technology', 'Technology1', 'Technology2'
];

const demoCities = [
  'Kaunas', 'Vilnius', 'Klaipėda', 'Šiauliai'
];

new Vue({
  el: "#category-select",
  data: {
    value: '',
    options: demoCats
  },

  mounted() {
    const url = new URL(window.location);

    let value = url.searchParams.get('company');
    if (value) {
      value = value.split(',');
      this.value = value;
    }

  },

  methods: {
    categories() {
      // if (this.value) window.location.href=this.value
      const url = new URL(window.location);
      url.searchParams.set('company', this.value);
      window.history.pushState({}, '', url);
      window.location.reload();
    },
    clearList(index) {
      this.value = [];

      const url = new URL(window.location);
      url.searchParams.delete('company');
      window.history.pushState({}, '', url);
      window.location.reload();
    },
  }
});


new Vue({
  el: "#location-select",
  data: {
    value: '',
    options: demoCities
  },

  mounted() {
    const url = new URL(window.location);

    let value = url.searchParams.get('country');
    if (value) {
      value = value.split(',');
      this.value = value;
    }
  },

  methods: {

    country() {
      // if (this.value) window.location.href=this.value
      const url = new URL(window.location);
      url.searchParams.set('country', this.value);
      window.history.pushState({}, '', url);
      window.location.reload();
    },
    clearList(index) {
      this.value = [];

      const url = new URL(window.location);
      url.searchParams.delete('country');
      window.history.pushState({}, '', url);
      window.location.reload();
    },
  }
});
